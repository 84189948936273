export function slider () {

  function sliderInit() {

    const certificate = new Swiper('.certificate__slider', {
      // centeredSlides: true,
      slidesPerView: 4,
      spaceBetween: 40,
      loop: false,

      navigation: {
        nextEl: '.certificate-button-prev',
        prevEl: '.certificate-button-next',
      },

      breakpoints: {
        320: {
          slidesPerView: 3,
          spaceBetween: 20,
        },

        767: {
          slidesPerView: 3,
          spaceBetween: 20,
        },

        1199: {
          slidesPerView: 3,
          spaceBetween: 30,
        },

        1600: {
          slidesPerView: 4,
          spaceBetween: 40,
        },
      },


    });

    const gallery = new Swiper('.gallery__slider', {
      // centeredSlides: true,
      slidesPerView: 4,
      spaceBetween: 40,
      loop: false,

      navigation: {
        nextEl: '.gallery-button-prev',
        prevEl: '.gallery-button-next',
      },

      breakpoints: {
        320: {
          slidesPerView: 3,
          spaceBetween: 20,
        },

        767: {
          slidesPerView: 3,
          spaceBetween: 20,
        },

        1199: {
          slidesPerView: 3,
          spaceBetween: 30,
        },

        1600: {
          slidesPerView: 4,
          spaceBetween: 40,
        },
      },


    });

    const card = new Swiper('.card__list-wrapper', {
      centeredSlides: false,
      slidesPerView: 8,
      spaceBetween: 40,
      loop: true,

      navigation: {
        nextEl: '.card-button-next',
        prevEl: '.card-button-prev',
      },

      breakpoints: {
        320: {
          slidesPerView: 3,
          spaceBetween: 10,
        },

      //
        767: {
          slidesPerView: 3,
          spaceBetween: 20,
        },
      //
        1199: {
          slidesPerView: 7,
          spaceBetween: 40,
        },
      //
      //   1600: {
      //     slidesPerView: 4,
      //     spaceBetween: 40,
      //   },
      },


    });

  }
  sliderInit()

}

